import React, { useEffect, useRef, useState } from "react";
import "./navbar.scss";
import logo from "../../assets/images/logo.png";
// import calender from "../../assets/images/calender.png";
// import moneybag from "../../assets/images/moneybag.png";
import humburger from "../../assets/images/humburger.png";
import { FaPhoneAlt } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import { mainNavLinks } from "../../assets/data/navlinks/mainNavLinks";
import { IoIosArrowDown } from "react-icons/io";
import { GoArrowUpRight } from "react-icons/go";
import { FaSackDollar } from "react-icons/fa6";
import { BsCalendar2CheckFill } from "react-icons/bs";
import { TbBusinessplan } from "react-icons/tb";

const Navbar = () => {
  const openModal = () => {
    if (window.HCPWidget && typeof window.HCPWidget.openModal === "function") {
      window.HCPWidget.openModal();
    }
  };

  const [open, setOpen] = useState(false);

  const [dropdown, setDropdown] = useState(
    mainNavLinks.reduce((acc, item) => {
      if (item.children) {
        acc[item.id] = true;
      } else {
        acc[item.id] = false;
      }
      return acc;
    }, {})
  );

  let menuRef = useRef();

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleLinkClick = () => {
    setOpen(false);
  };

  return (
    <div className="navbar" ref={menuRef}>
      <div className="desktop">
        <div className="container">
          <div className="row">
            <div className="column small">
              <div className="inner">
                <Link to={"/"}>
                  {" "}
                  <img src={logo} alt="logo" className="logo" />
                </Link>
                <div
                  className="mobile-menu-icon"
                  onClick={() => setOpen(!open)}
                >
                  <img src={humburger} alt="humburger" className="humburger" />
                </div>
                <Link to={"/"}>
                  {" "}
                  <h1 className="">STL Handypros</h1>
                </Link>
              </div>
            </div>

            <div className="column large">
              <div className="inner-wrapper">
                <div className="inner">
                  <Link
                    data-token="765abcf35e624c58b5680fa0a1ca9a95"
                    data-orgname="STL-HandyPros"
                    class="hcp-button"
                    onClick={openModal}
                  >
                    {/* <img src={calender} alt="calender" className="calender" /> */}
                    <BsCalendar2CheckFill />
                    <p className="">Schedule appointment</p>
                  </Link>
                </div>
                {/* <div className="inner">
                  <Link to={"https://forms.gle/4TEz4KqgvU9g5dUS9"} target="_blank">
                  <TbBusinessplan />
                    <p className="">Annual Plans</p>
                  </Link>
                </div> */}
                <div className="inner">
                  <Link to={"/contact-us"}>
                    <FaSackDollar />
                    {/* <img src={moneybag} alt="moneybag" className="moneybag" /> */}
                    <p className="">get an estimate</p>
                  </Link>
                </div>
                <div className="inner">
                  <Link to={"tel:+13148041097"}>
                    <FaPhoneAlt />
                    <p className="">314-804-1097</p>
                  </Link>
                </div>
              </div>
            </div>

            {open && (
              <div className="mobile-menu">
                <ul className="mobile-nav-links">
                  {mainNavLinks.map((item) => {
                    if (!item.children) {
                      return (
                        <li key={item.id} onClick={handleLinkClick}>
                          <NavLink to={item.path}>{item.name}</NavLink>
                        </li>
                      );
                    }

                    return (
                      <li key={item.id}>
                        <div className="mobile-dropdown">
                          <NavLink to={item.path}>{item.name}</NavLink>
                          {dropdown[item.id] && (
                            <IoIosArrowDown className="dropdown-icon" />
                          )}
                        </div>

                        {dropdown[item.id] && (
                          <ul className="mobile-dropdown-menu">
                            {item.children.map((childItem) => (
                              <li key={childItem.id}>
                                <NavLink to={childItem.path}>
                                  {childItem.name}
                                  <div className="icon-box">
                                    <GoArrowUpRight className="upright-arrow-icon" />
                                  </div>
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="outer">
        <div className="menu">
          <ul className="nav-links">
            {mainNavLinks.map((item) => {
              if (!item.children) {
                return (
                  <li key={item.id}>
                    <NavLink to={item.path}>{item.name}</NavLink>
                  </li>
                );
              }

              return (
                <li key={item.id}>
                  <div className="item">
                    <NavLink to={item.path}>{item.name}</NavLink>
                    {dropdown[item.id] && (
                      <IoIosArrowDown className="dropdown-icon" />
                    )}
                  </div>

                  {dropdown[item.id] && (
                    <ul className="dropdown-menu">
                      {item.children.map((childItem) => (
                        <li key={childItem.id}>
                          <NavLink to={childItem.path}>
                            {childItem.name}
                            <div className="icon-box">
                              <GoArrowUpRight className="upright-arrow-icon" />
                            </div>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
