import livingRoom from "../../assets/images/services/living-room.jpg";
import bedroom from "../../assets/images/services/bedroom.jpg";
import flooring from "../../assets/images/services/flooring.jpg";
import drywall from "../../assets/images/services/drywall.jpg";
import furnitureAssembly from "../../assets/images/services/furniture-assembly.jpg";
import applianceInstallation from "../../assets/images/services/appliance-installation.jpg";
import bathroomRepair from "../../assets/images/services/bathroom-repair.jpg";
import kitchenRepair from "../../assets/images/services/kitchen-repair.jpg";
import office from "../../assets/images/services/office.jpg";
import doorInstallation from "../../assets/images/services/door-installation.jpg";
import cabinetInstallation from "../../assets/images/services/cabinet-installation.jpg";
import backsplash from "../../assets/images/services/backsplash.jpg";
import preventative from "../../assets/images/services/preventative.jpg";
import homeRepair from "../../assets/images/services/home-repair.jpg";

export const services = [
  // Living Room
  {
    id: "living-room",
    thumbnail: livingRoom,
    title: "Living Room",
    desc: "At STL HandyPros, we specialize in enhancing your living space with our precision wall installations. From expert TV mounting to seamless picture hanging and efficient blind installations, our skilled team ensures a polished and functional finish to your walls. Whether it's creating the perfect entertainment setup with secure TV mounting, adding a personal touch with precise picture placements, or enhancing privacy and aesthetics with flawlessly installed blinds, we handle each task with attention to detail. Trust us to elevate your space and bring your vision to life with our professional wall installation services.",
    accordions: [
      {
        title: "Cabinet Installation & Repair",
        desc: "Transform your space with STL HandyPros' skilled cabinet installation and repair services. Whether you're envisioning the addition of stylish new cabinets or seeking efficient repairs, our expert team is at your service. From precise installations that enhance the aesthetics of your kitchen or bathroom to meticulous repairs that restore functionality, we approach every project with a commitment to quality. Rely on STL HandyPros to bring versatility and craftsmanship to your cabinet needs. Contact us today, and let's explore how our services can tailor to meet your specific requirements. Please also use this on the other pages accordions",
      },
      {
        title: "Kitchen Backsplash Installation",
        desc: "Adding a backsplash in your kitchen is a stylish and modern method to introduce color and dimension to your space. Recognizing the complexity of this project, our skilled experts at STL HandyPros are trained and equipped with the necessary tools to ensure a flawless execution. Let us assist you in discovering the perfect pattern to enhance the natural beauty and personality of your kitchen. Reach out today to initiate a conversation about your backsplash project – we're here to bring your vision to life!",
      },
      {
        title: "Flooring",
        desc: "Revitalize your space with STL HandyPros' expert flooring services. Our skilled team excels in transforming interiors with flawless flooring installations, whether it's hardwood, laminate or tile. From the initial consultation to meticulous installation, we ensure a seamless process focused on precision and quality. Trust us to not only enhance the aesthetics of your space but also improve its durability and functionality. Whether it's a minor repair or a complete flooring overhaul, STL HandyPros is your trusted partner for top-notch flooring solutions. Elevate your home with our expertise – contact us today to discuss your flooring project.",
      },
      {
        title: "Closets and Pantries",
        desc: "Transform your living spaces with tailored storage solutions from STL HandyPros. Our experts specialize in creating custom pantry and closet designs that not only maximize space but also reflect your unique style. From conceptualization to precise installation, we ensure every detail is considered to enhance functionality and organization. Say goodbye to clutter and hello to personalized, well-organized spaces that elevate the aesthetics of your home. Contact STL HandyPros today to discuss how our custom pantry and closet solutions can bring both efficiency and elegance to your living spaces.",
      },
    ],
  },

  {
    id: "bedroom",
    thumbnail: bedroom,
    title: "Bedroom",
    desc: "Transform your bedroom into a serene sanctuary or revitalize its allure with our comprehensive bedroom remodeling and maintenance services. Whether it's a complete makeover or subtle enhancements, our skilled team specializes in elevating your space to reflect your unique style and comfort preferences. From custom storage solutions to refreshing paintwork, lighting upgrades, flooring renovations, and fixture replacements, we handle every detail with meticulous care. Additionally, our maintenance services ensure your bedroom remains a haven of tranquility by addressing any wear-and-tear, fixing fixtures, and keeping the space in top-notch condition. Trust us to turn your bedroom dreams into reality while ensuring its long-term upkeep and functionality.",
    accordions: [
      {
        title: "Cabinet Installation & Repair",
        desc: "Transform your space with STL HandyPros' skilled cabinet installation and repair services. Whether you're envisioning the addition of stylish new cabinets or seeking efficient repairs, our expert team is at your service. From precise installations that enhance the aesthetics of your kitchen or bathroom to meticulous repairs that restore functionality, we approach every project with a commitment to quality. Rely on STL HandyPros to bring versatility and craftsmanship to your cabinet needs. Contact us today, and let's explore how our services can tailor to meet your specific requirements. Please also use this on the other pages accordions",
      },
      {
        title: "Kitchen Backsplash Installation",
        desc: "Adding a backsplash in your kitchen is a stylish and modern method to introduce color and dimension to your space. Recognizing the complexity of this project, our skilled experts at STL HandyPros are trained and equipped with the necessary tools to ensure a flawless execution. Let us assist you in discovering the perfect pattern to enhance the natural beauty and personality of your kitchen. Reach out today to initiate a conversation about your backsplash project – we're here to bring your vision to life!",
      },
      {
        title: "Flooring",
        desc: "Revitalize your space with STL HandyPros' expert flooring services. Our skilled team excels in transforming interiors with flawless flooring installations, whether it's hardwood, laminate or tile. From the initial consultation to meticulous installation, we ensure a seamless process focused on precision and quality. Trust us to not only enhance the aesthetics of your space but also improve its durability and functionality. Whether it's a minor repair or a complete flooring overhaul, STL HandyPros is your trusted partner for top-notch flooring solutions. Elevate your home with our expertise – contact us today to discuss your flooring project.",
      },
      {
        title: "Closets and Pantries",
        desc: "Transform your living spaces with tailored storage solutions from STL HandyPros. Our experts specialize in creating custom pantry and closet designs that not only maximize space but also reflect your unique style. From conceptualization to precise installation, we ensure every detail is considered to enhance functionality and organization. Say goodbye to clutter and hello to personalized, well-organized spaces that elevate the aesthetics of your home. Contact STL HandyPros today to discuss how our custom pantry and closet solutions can bring both efficiency and elegance to your living spaces.",
      },
    ],
  },

  {
    id: "flooring",
    thumbnail: flooring,
    title: "Flooring",
    desc: "At STL HandyPros, we're dedicated to providing top-tier flooring installation and repair services tailored to meet your needs. Our team of skilled handypros excels in transforming spaces with flawless flooring installations, whether it's hardwood, laminate, tile, or carpet. From initial consultation to meticulous installation, we ensure a seamless process, focusing on precision and quality. Additionally, count on us for expert flooring repairs, addressing issues such as scratches, uneven surfaces, or damaged sections. We're committed to revitalizing your floors, enhancing the aesthetics, durability, and functionality of your space, ensuring a long-lasting and beautiful foundation for your home.",
    accordions: [
      {
        title: "Cabinet Installation & Repair",
        desc: "Transform your space with STL HandyPros' skilled cabinet installation and repair services. Whether you're envisioning the addition of stylish new cabinets or seeking efficient repairs, our expert team is at your service. From precise installations that enhance the aesthetics of your kitchen or bathroom to meticulous repairs that restore functionality, we approach every project with a commitment to quality. Rely on STL HandyPros to bring versatility and craftsmanship to your cabinet needs. Contact us today, and let's explore how our services can tailor to meet your specific requirements. Please also use this on the other pages accordions",
      },
      {
        title: "Kitchen Backsplash Installation",
        desc: "Adding a backsplash in your kitchen is a stylish and modern method to introduce color and dimension to your space. Recognizing the complexity of this project, our skilled experts at STL HandyPros are trained and equipped with the necessary tools to ensure a flawless execution. Let us assist you in discovering the perfect pattern to enhance the natural beauty and personality of your kitchen. Reach out today to initiate a conversation about your backsplash project – we're here to bring your vision to life!",
      },
      {
        title: "Flooring",
        desc: "Revitalize your space with STL HandyPros' expert flooring services. Our skilled team excels in transforming interiors with flawless flooring installations, whether it's hardwood, laminate or tile. From the initial consultation to meticulous installation, we ensure a seamless process focused on precision and quality. Trust us to not only enhance the aesthetics of your space but also improve its durability and functionality. Whether it's a minor repair or a complete flooring overhaul, STL HandyPros is your trusted partner for top-notch flooring solutions. Elevate your home with our expertise – contact us today to discuss your flooring project.",
      },
      {
        title: "Closets and Pantries",
        desc: "Transform your living spaces with tailored storage solutions from STL HandyPros. Our experts specialize in creating custom pantry and closet designs that not only maximize space but also reflect your unique style. From conceptualization to precise installation, we ensure every detail is considered to enhance functionality and organization. Say goodbye to clutter and hello to personalized, well-organized spaces that elevate the aesthetics of your home. Contact STL HandyPros today to discuss how our custom pantry and closet solutions can bring both efficiency and elegance to your living spaces.",
      },
    ],
  },

  {
    id: "drywall-interior-trim-and-painting",
    thumbnail: drywall,
    title: "Drywall, Interior Trim & Painting",
    desc: "At STL HandyPros, we specialize in elevating interior spaces with our expertise in drywall, interior trim, and painting services. Our skilled HandyPros are adept at delivering flawless drywall installation, repair, or replacement, ensuring seamless walls and ceilings. Whether it's intricate trim work or enhancing the aesthetics with crown molding, baseboards, or wainscoting, our attention to detail creates a refined finish that transforms any room. Moreover, our painting services add the final touch, offering a spectrum of colors and finishes to breathe new life into your space. Trust us to harmonize these elements, crafting interiors that radiate beauty, durability, and sophistication, tailored to your unique vision and preferences.    ",
    accordions: [
      {
        title: "Cabinet Installation & Repair",
        desc: "Transform your space with STL HandyPros' skilled cabinet installation and repair services. Whether you're envisioning the addition of stylish new cabinets or seeking efficient repairs, our expert team is at your service. From precise installations that enhance the aesthetics of your kitchen or bathroom to meticulous repairs that restore functionality, we approach every project with a commitment to quality. Rely on STL HandyPros to bring versatility and craftsmanship to your cabinet needs. Contact us today, and let's explore how our services can tailor to meet your specific requirements. Please also use this on the other pages accordions",
      },
      {
        title: "Kitchen Backsplash Installation",
        desc: "Adding a backsplash in your kitchen is a stylish and modern method to introduce color and dimension to your space. Recognizing the complexity of this project, our skilled experts at STL HandyPros are trained and equipped with the necessary tools to ensure a flawless execution. Let us assist you in discovering the perfect pattern to enhance the natural beauty and personality of your kitchen. Reach out today to initiate a conversation about your backsplash project – we're here to bring your vision to life!",
      },
      {
        title: "Flooring",
        desc: "Revitalize your space with STL HandyPros' expert flooring services. Our skilled team excels in transforming interiors with flawless flooring installations, whether it's hardwood, laminate or tile. From the initial consultation to meticulous installation, we ensure a seamless process focused on precision and quality. Trust us to not only enhance the aesthetics of your space but also improve its durability and functionality. Whether it's a minor repair or a complete flooring overhaul, STL HandyPros is your trusted partner for top-notch flooring solutions. Elevate your home with our expertise – contact us today to discuss your flooring project.",
      },
      {
        title: "Closets and Pantries",
        desc: "Transform your living spaces with tailored storage solutions from STL HandyPros. Our experts specialize in creating custom pantry and closet designs that not only maximize space but also reflect your unique style. From conceptualization to precise installation, we ensure every detail is considered to enhance functionality and organization. Say goodbye to clutter and hello to personalized, well-organized spaces that elevate the aesthetics of your home. Contact STL HandyPros today to discuss how our custom pantry and closet solutions can bring both efficiency and elegance to your living spaces.",
      },
    ],
  },

  {
    id: "furniture-assembly",
    thumbnail: furnitureAssembly,
    title: "Furniture Assembly",
    desc: "At STL HandyPros, we excel in furniture assembly, offering expert services to make your life easier. Our skilled HandyPros specialize in assembling various furniture pieces, whether it's from renowned brands or custom designs. We meticulously handle each component, ensuring precision and stability in every assembly. From intricate details to large-scale projects, count on our HandyPros to swiftly and efficiently assemble your furniture, saving you time and hassle. Let us take the stress out of furniture assembly, allowing you to enjoy your new pieces in no time.    ",
    accordions: [
      {
        title: "Cabinet Installation & Repair",
        desc: "Transform your space with STL HandyPros' skilled cabinet installation and repair services. Whether you're envisioning the addition of stylish new cabinets or seeking efficient repairs, our expert team is at your service. From precise installations that enhance the aesthetics of your kitchen or bathroom to meticulous repairs that restore functionality, we approach every project with a commitment to quality. Rely on STL HandyPros to bring versatility and craftsmanship to your cabinet needs. Contact us today, and let's explore how our services can tailor to meet your specific requirements. Please also use this on the other pages accordions",
      },
      {
        title: "Kitchen Backsplash Installation",
        desc: "Adding a backsplash in your kitchen is a stylish and modern method to introduce color and dimension to your space. Recognizing the complexity of this project, our skilled experts at STL HandyPros are trained and equipped with the necessary tools to ensure a flawless execution. Let us assist you in discovering the perfect pattern to enhance the natural beauty and personality of your kitchen. Reach out today to initiate a conversation about your backsplash project – we're here to bring your vision to life!",
      },
      {
        title: "Flooring",
        desc: "Revitalize your space with STL HandyPros' expert flooring services. Our skilled team excels in transforming interiors with flawless flooring installations, whether it's hardwood, laminate or tile. From the initial consultation to meticulous installation, we ensure a seamless process focused on precision and quality. Trust us to not only enhance the aesthetics of your space but also improve its durability and functionality. Whether it's a minor repair or a complete flooring overhaul, STL HandyPros is your trusted partner for top-notch flooring solutions. Elevate your home with our expertise – contact us today to discuss your flooring project.",
      },
      {
        title: "Closets and Pantries",
        desc: "Transform your living spaces with tailored storage solutions from STL HandyPros. Our experts specialize in creating custom pantry and closet designs that not only maximize space but also reflect your unique style. From conceptualization to precise installation, we ensure every detail is considered to enhance functionality and organization. Say goodbye to clutter and hello to personalized, well-organized spaces that elevate the aesthetics of your home. Contact STL HandyPros today to discuss how our custom pantry and closet solutions can bring both efficiency and elegance to your living spaces.",
      },
    ],
  },

  {
    id: "appliance-installation",
    thumbnail: applianceInstallation,
    title: "Appliance Installation",
    desc: "At STL HandyPros, we take the hassle out of appliance installation and repair. Our skilled team specializes in seamless and efficient appliance installations, ensuring everything from ovens and dishwashers to refrigerators and washing machines are set up and functioning flawlessly in your home. Moreover, count on us for reliable appliance repair services. Whether it's a malfunctioning dishwasher, a refrigerator on the fritz, or any other appliance issue, our experts diagnose and fix the problem swiftly, ensuring your household runs smoothly. Trust us to handle your appliance needs with professionalism and expertise, providing reliable installations and timely repairs to keep your home running seamlessly.",
    accordions: [
      {
        title: "Cabinet Installation & Repair",
        desc: "Transform your space with STL HandyPros' skilled cabinet installation and repair services. Whether you're envisioning the addition of stylish new cabinets or seeking efficient repairs, our expert team is at your service. From precise installations that enhance the aesthetics of your kitchen or bathroom to meticulous repairs that restore functionality, we approach every project with a commitment to quality. Rely on STL HandyPros to bring versatility and craftsmanship to your cabinet needs. Contact us today, and let's explore how our services can tailor to meet your specific requirements. Please also use this on the other pages accordions",
      },
      {
        title: "Kitchen Backsplash Installation",
        desc: "Adding a backsplash in your kitchen is a stylish and modern method to introduce color and dimension to your space. Recognizing the complexity of this project, our skilled experts at STL HandyPros are trained and equipped with the necessary tools to ensure a flawless execution. Let us assist you in discovering the perfect pattern to enhance the natural beauty and personality of your kitchen. Reach out today to initiate a conversation about your backsplash project – we're here to bring your vision to life!",
      },
      {
        title: "Flooring",
        desc: "Revitalize your space with STL HandyPros' expert flooring services. Our skilled team excels in transforming interiors with flawless flooring installations, whether it's hardwood, laminate or tile. From the initial consultation to meticulous installation, we ensure a seamless process focused on precision and quality. Trust us to not only enhance the aesthetics of your space but also improve its durability and functionality. Whether it's a minor repair or a complete flooring overhaul, STL HandyPros is your trusted partner for top-notch flooring solutions. Elevate your home with our expertise – contact us today to discuss your flooring project.",
      },
      {
        title: "Closets and Pantries",
        desc: "Transform your living spaces with tailored storage solutions from STL HandyPros. Our experts specialize in creating custom pantry and closet designs that not only maximize space but also reflect your unique style. From conceptualization to precise installation, we ensure every detail is considered to enhance functionality and organization. Say goodbye to clutter and hello to personalized, well-organized spaces that elevate the aesthetics of your home. Contact STL HandyPros today to discuss how our custom pantry and closet solutions can bring both efficiency and elegance to your living spaces.",
      },
    ],
  },

  {
    id: "bathroom-repair-and-refresh",
    thumbnail: bathroomRepair,
    title: "Bathroom Repair & Refresh",
    desc: "At STL HandyPros, we specialize in transforming bathrooms into serene and functional spaces through our expert remodeling services. Whether it's a simple update or a complete overhaul, our skilled team handles every aspect of bathroom remodeling with precision and care. From installing new fixtures and fittings to revamping tiling, cabinetry, and lighting, we ensure a seamless and stylish renovation tailored to your vision. Count on us for innovative design ideas, quality craftsmanship, and a commitment to delivering a bathroom that reflects your unique taste and enhances your daily comfort.",
    accordions: [
      {
        title: "Cabinet Installation & Repair",
        desc: "Transform your space with STL HandyPros' skilled cabinet installation and repair services. Whether you're envisioning the addition of stylish new cabinets or seeking efficient repairs, our expert team is at your service. From precise installations that enhance the aesthetics of your kitchen or bathroom to meticulous repairs that restore functionality, we approach every project with a commitment to quality. Rely on STL HandyPros to bring versatility and craftsmanship to your cabinet needs. Contact us today, and let's explore how our services can tailor to meet your specific requirements. Please also use this on the other pages accordions",
      },
      {
        title: "Kitchen Backsplash Installation",
        desc: "Adding a backsplash in your kitchen is a stylish and modern method to introduce color and dimension to your space. Recognizing the complexity of this project, our skilled experts at STL HandyPros are trained and equipped with the necessary tools to ensure a flawless execution. Let us assist you in discovering the perfect pattern to enhance the natural beauty and personality of your kitchen. Reach out today to initiate a conversation about your backsplash project – we're here to bring your vision to life!",
      },
      {
        title: "Flooring",
        desc: "Revitalize your space with STL HandyPros' expert flooring services. Our skilled team excels in transforming interiors with flawless flooring installations, whether it's hardwood, laminate or tile. From the initial consultation to meticulous installation, we ensure a seamless process focused on precision and quality. Trust us to not only enhance the aesthetics of your space but also improve its durability and functionality. Whether it's a minor repair or a complete flooring overhaul, STL HandyPros is your trusted partner for top-notch flooring solutions. Elevate your home with our expertise – contact us today to discuss your flooring project.",
      },
      {
        title: "Closets and Pantries",
        desc: "Transform your living spaces with tailored storage solutions from STL HandyPros. Our experts specialize in creating custom pantry and closet designs that not only maximize space but also reflect your unique style. From conceptualization to precise installation, we ensure every detail is considered to enhance functionality and organization. Say goodbye to clutter and hello to personalized, well-organized spaces that elevate the aesthetics of your home. Contact STL HandyPros today to discuss how our custom pantry and closet solutions can bring both efficiency and elegance to your living spaces.",
      },
    ],
  },

  {
    id: "kitchen-repair-and-refresh",
    thumbnail: kitchenRepair,
    title: "Kitchen Repair & Refresh",
    desc: "At STL HandyPros, we're dedicated to revitalizing kitchens with our comprehensive update and repair services. Whether it's a minor fix or a complete kitchen overhaul, our skilled team handles every aspect of updates and repairs with expertise and precision. From repairing countertops and cabinetry to updating fixtures, installing new appliances, or refreshing paintwork, we ensure a seamless and functional kitchen space tailored to your needs. Count on us to bring innovation and quality craftsmanship to every project, transforming your kitchen into a modern, efficient, and inviting hub for your home.    ",
    accordions: [
      {
        title: "Cabinet Installation & Repair",
        desc: "Transform your space with STL HandyPros' skilled cabinet installation and repair services. Whether you're envisioning the addition of stylish new cabinets or seeking efficient repairs, our expert team is at your service. From precise installations that enhance the aesthetics of your kitchen or bathroom to meticulous repairs that restore functionality, we approach every project with a commitment to quality. Rely on STL HandyPros to bring versatility and craftsmanship to your cabinet needs. Contact us today, and let's explore how our services can tailor to meet your specific requirements. Please also use this on the other pages accordions",
      },
      {
        title: "Kitchen Backsplash Installation",
        desc: "Adding a backsplash in your kitchen is a stylish and modern method to introduce color and dimension to your space. Recognizing the complexity of this project, our skilled experts at STL HandyPros are trained and equipped with the necessary tools to ensure a flawless execution. Let us assist you in discovering the perfect pattern to enhance the natural beauty and personality of your kitchen. Reach out today to initiate a conversation about your backsplash project – we're here to bring your vision to life!",
      },
      {
        title: "Flooring",
        desc: "Revitalize your space with STL HandyPros' expert flooring services. Our skilled team excels in transforming interiors with flawless flooring installations, whether it's hardwood, laminate or tile. From the initial consultation to meticulous installation, we ensure a seamless process focused on precision and quality. Trust us to not only enhance the aesthetics of your space but also improve its durability and functionality. Whether it's a minor repair or a complete flooring overhaul, STL HandyPros is your trusted partner for top-notch flooring solutions. Elevate your home with our expertise – contact us today to discuss your flooring project.",
      },
      {
        title: "Closets and Pantries",
        desc: "Transform your living spaces with tailored storage solutions from STL HandyPros. Our experts specialize in creating custom pantry and closet designs that not only maximize space but also reflect your unique style. From conceptualization to precise installation, we ensure every detail is considered to enhance functionality and organization. Say goodbye to clutter and hello to personalized, well-organized spaces that elevate the aesthetics of your home. Contact STL HandyPros today to discuss how our custom pantry and closet solutions can bring both efficiency and elegance to your living spaces.",
      },
    ],
  },

  {
    id: "office",
    thumbnail: office,
    title: "Office",
    desc: "At STL HandyPros, we specialize in optimizing office spaces to enhance productivity and functionality. Our skilled team offers a range of services tailored to suit your office needs. Whether it's reconfiguring layouts for better workflow, installing shelving units for efficient organization, updating lighting fixtures for enhanced ambiance, or addressing minor repairs to ensure a professional environment, we're here to elevate your office space. Trust us to transform your office into a productive and comfortable workspace that reflects your professional standards and maximizes efficiency.    ",
    accordions: [
      {
        title: "Cabinet Installation & Repair",
        desc: "Transform your space with STL HandyPros' skilled cabinet installation and repair services. Whether you're envisioning the addition of stylish new cabinets or seeking efficient repairs, our expert team is at your service. From precise installations that enhance the aesthetics of your kitchen or bathroom to meticulous repairs that restore functionality, we approach every project with a commitment to quality. Rely on STL HandyPros to bring versatility and craftsmanship to your cabinet needs. Contact us today, and let's explore how our services can tailor to meet your specific requirements. Please also use this on the other pages accordions",
      },
      {
        title: "Kitchen Backsplash Installation",
        desc: "Adding a backsplash in your kitchen is a stylish and modern method to introduce color and dimension to your space. Recognizing the complexity of this project, our skilled experts at STL HandyPros are trained and equipped with the necessary tools to ensure a flawless execution. Let us assist you in discovering the perfect pattern to enhance the natural beauty and personality of your kitchen. Reach out today to initiate a conversation about your backsplash project – we're here to bring your vision to life!",
      },
      {
        title: "Flooring",
        desc: "Revitalize your space with STL HandyPros' expert flooring services. Our skilled team excels in transforming interiors with flawless flooring installations, whether it's hardwood, laminate or tile. From the initial consultation to meticulous installation, we ensure a seamless process focused on precision and quality. Trust us to not only enhance the aesthetics of your space but also improve its durability and functionality. Whether it's a minor repair or a complete flooring overhaul, STL HandyPros is your trusted partner for top-notch flooring solutions. Elevate your home with our expertise – contact us today to discuss your flooring project.",
      },
      {
        title: "Closets and Pantries",
        desc: "Transform your living spaces with tailored storage solutions from STL HandyPros. Our experts specialize in creating custom pantry and closet designs that not only maximize space but also reflect your unique style. From conceptualization to precise installation, we ensure every detail is considered to enhance functionality and organization. Say goodbye to clutter and hello to personalized, well-organized spaces that elevate the aesthetics of your home. Contact STL HandyPros today to discuss how our custom pantry and closet solutions can bring both efficiency and elegance to your living spaces.",
      },
    ],
  },

  {
    id: "door-installation",
    thumbnail: doorInstallation,
    title: "Door Installation",
    desc: "At STL HandyPros, our door installation service combines expertise and precision to upgrade your home's functionality and aesthetics. Whether it's interior or exterior doors, our skilled professionals handle the entire installation process with meticulous attention to detail. From measuring and fitting to securing and finishing, we ensure a seamless and secure fit for your new doors. Trust us to enhance your home's appeal and functionality with our reliable and top-quality door installation service    ",
    accordions: [
      {
        title: "Cabinet Installation & Repair",
        desc: "Transform your space with STL HandyPros' skilled cabinet installation and repair services. Whether you're envisioning the addition of stylish new cabinets or seeking efficient repairs, our expert team is at your service. From precise installations that enhance the aesthetics of your kitchen or bathroom to meticulous repairs that restore functionality, we approach every project with a commitment to quality. Rely on STL HandyPros to bring versatility and craftsmanship to your cabinet needs. Contact us today, and let's explore how our services can tailor to meet your specific requirements. Please also use this on the other pages accordions",
      },
      {
        title: "Kitchen Backsplash Installation",
        desc: "Adding a backsplash in your kitchen is a stylish and modern method to introduce color and dimension to your space. Recognizing the complexity of this project, our skilled experts at STL HandyPros are trained and equipped with the necessary tools to ensure a flawless execution. Let us assist you in discovering the perfect pattern to enhance the natural beauty and personality of your kitchen. Reach out today to initiate a conversation about your backsplash project – we're here to bring your vision to life!",
      },
      {
        title: "Flooring",
        desc: "Revitalize your space with STL HandyPros' expert flooring services. Our skilled team excels in transforming interiors with flawless flooring installations, whether it's hardwood, laminate or tile. From the initial consultation to meticulous installation, we ensure a seamless process focused on precision and quality. Trust us to not only enhance the aesthetics of your space but also improve its durability and functionality. Whether it's a minor repair or a complete flooring overhaul, STL HandyPros is your trusted partner for top-notch flooring solutions. Elevate your home with our expertise – contact us today to discuss your flooring project.",
      },
      {
        title: "Closets and Pantries",
        desc: "Transform your living spaces with tailored storage solutions from STL HandyPros. Our experts specialize in creating custom pantry and closet designs that not only maximize space but also reflect your unique style. From conceptualization to precise installation, we ensure every detail is considered to enhance functionality and organization. Say goodbye to clutter and hello to personalized, well-organized spaces that elevate the aesthetics of your home. Contact STL HandyPros today to discuss how our custom pantry and closet solutions can bring both efficiency and elegance to your living spaces.",
      },
    ],
  },

  {
    id: "cabinet-installation-and-repair",
    thumbnail: cabinetInstallation,
    title: "Cabinet Installation & Repair",
    desc: "At STL HandyPros, we excel in cabinetry services designed to elevate your space with functional and stylish storage solutions. Our skilled team specializes in cabinetry installations, repairs, and upgrades tailored to your specific needs. Whether it's installing custom cabinets for optimized storage, repairing existing units for functionality, or refreshing cabinetry with modern finishes, we ensure meticulous craftsmanship and attention to detail. Count on us to transform your space with expertly crafted cabinetry that enhances both the aesthetics and functionality of your home.    ",
    accordions: [
      {
        title: "Cabinet Installation & Repair",
        desc: "Transform your space with STL HandyPros' skilled cabinet installation and repair services. Whether you're envisioning the addition of stylish new cabinets or seeking efficient repairs, our expert team is at your service. From precise installations that enhance the aesthetics of your kitchen or bathroom to meticulous repairs that restore functionality, we approach every project with a commitment to quality. Rely on STL HandyPros to bring versatility and craftsmanship to your cabinet needs. Contact us today, and let's explore how our services can tailor to meet your specific requirements. Please also use this on the other pages accordions",
      },
      {
        title: "Kitchen Backsplash Installation",
        desc: "Adding a backsplash in your kitchen is a stylish and modern method to introduce color and dimension to your space. Recognizing the complexity of this project, our skilled experts at STL HandyPros are trained and equipped with the necessary tools to ensure a flawless execution. Let us assist you in discovering the perfect pattern to enhance the natural beauty and personality of your kitchen. Reach out today to initiate a conversation about your backsplash project – we're here to bring your vision to life!",
      },
      {
        title: "Flooring",
        desc: "Revitalize your space with STL HandyPros' expert flooring services. Our skilled team excels in transforming interiors with flawless flooring installations, whether it's hardwood, laminate or tile. From the initial consultation to meticulous installation, we ensure a seamless process focused on precision and quality. Trust us to not only enhance the aesthetics of your space but also improve its durability and functionality. Whether it's a minor repair or a complete flooring overhaul, STL HandyPros is your trusted partner for top-notch flooring solutions. Elevate your home with our expertise – contact us today to discuss your flooring project.",
      },
      {
        title: "Closets and Pantries",
        desc: "Transform your living spaces with tailored storage solutions from STL HandyPros. Our experts specialize in creating custom pantry and closet designs that not only maximize space but also reflect your unique style. From conceptualization to precise installation, we ensure every detail is considered to enhance functionality and organization. Say goodbye to clutter and hello to personalized, well-organized spaces that elevate the aesthetics of your home. Contact STL HandyPros today to discuss how our custom pantry and closet solutions can bring both efficiency and elegance to your living spaces.",
      },
    ],
  },

  {
    id: "backsplash-and-countertops",
    thumbnail: backsplash,
    title: "Backsplash and Countertops",
    desc: "At STL HandyPros, we're dedicated to enhancing your kitchen or bathroom with our expert backsplash and countertop services. Our skilled team specializes in precise backsplash installations, offering a range of materials and designs to elevate the visual appeal and functionality of your space. Additionally, trust us for professional countertop installations or repairs, ensuring a seamless and durable surface that complements your style. Whether it's a stunning new backsplash to invigorate your kitchen or a flawless countertop upgrade, we bring craftsmanship and attention to detail to every project, creating a space that exudes both elegance and practicality.    ",
    accordions: [
      {
        title: "Cabinet Installation & Repair",
        desc: "Transform your space with STL HandyPros' skilled cabinet installation and repair services. Whether you're envisioning the addition of stylish new cabinets or seeking efficient repairs, our expert team is at your service. From precise installations that enhance the aesthetics of your kitchen or bathroom to meticulous repairs that restore functionality, we approach every project with a commitment to quality. Rely on STL HandyPros to bring versatility and craftsmanship to your cabinet needs. Contact us today, and let's explore how our services can tailor to meet your specific requirements. Please also use this on the other pages accordions",
      },
      {
        title: "Kitchen Backsplash Installation",
        desc: "Adding a backsplash in your kitchen is a stylish and modern method to introduce color and dimension to your space. Recognizing the complexity of this project, our skilled experts at STL HandyPros are trained and equipped with the necessary tools to ensure a flawless execution. Let us assist you in discovering the perfect pattern to enhance the natural beauty and personality of your kitchen. Reach out today to initiate a conversation about your backsplash project – we're here to bring your vision to life!",
      },
      {
        title: "Flooring",
        desc: "Revitalize your space with STL HandyPros' expert flooring services. Our skilled team excels in transforming interiors with flawless flooring installations, whether it's hardwood, laminate or tile. From the initial consultation to meticulous installation, we ensure a seamless process focused on precision and quality. Trust us to not only enhance the aesthetics of your space but also improve its durability and functionality. Whether it's a minor repair or a complete flooring overhaul, STL HandyPros is your trusted partner for top-notch flooring solutions. Elevate your home with our expertise – contact us today to discuss your flooring project.",
      },
      {
        title: "Closets and Pantries",
        desc: "Transform your living spaces with tailored storage solutions from STL HandyPros. Our experts specialize in creating custom pantry and closet designs that not only maximize space but also reflect your unique style. From conceptualization to precise installation, we ensure every detail is considered to enhance functionality and organization. Say goodbye to clutter and hello to personalized, well-organized spaces that elevate the aesthetics of your home. Contact STL HandyPros today to discuss how our custom pantry and closet solutions can bring both efficiency and elegance to your living spaces.",
      },
    ],
  },

  {
    id: "preventative-maintenance",
    thumbnail: preventative,
    title: "Preventative Maintenance",
    desc: "Preventive maintenance is key to preserving the longevity and efficiency of your home. At STL HandyPros, we offer comprehensive preventive maintenance services to safeguard your property. From inspecting and servicing HVAC systems, checking for plumbing leaks, and assessing electrical systems to conducting routine checks on structural integrity and ensuring proper insulation, our skilled team identifies potential issues before they escalate. Trust us to implement preventive measures that save you time and money in the long run, ensuring your home remains in optimal condition year-round.",
    accordions: [
      {
        title: "Cabinet Installation & Repair",
        desc: "Transform your space with STL HandyPros' skilled cabinet installation and repair services. Whether you're envisioning the addition of stylish new cabinets or seeking efficient repairs, our expert team is at your service. From precise installations that enhance the aesthetics of your kitchen or bathroom to meticulous repairs that restore functionality, we approach every project with a commitment to quality. Rely on STL HandyPros to bring versatility and craftsmanship to your cabinet needs. Contact us today, and let's explore how our services can tailor to meet your specific requirements. Please also use this on the other pages accordions",
      },
      {
        title: "Kitchen Backsplash Installation",
        desc: "Adding a backsplash in your kitchen is a stylish and modern method to introduce color and dimension to your space. Recognizing the complexity of this project, our skilled experts at STL HandyPros are trained and equipped with the necessary tools to ensure a flawless execution. Let us assist you in discovering the perfect pattern to enhance the natural beauty and personality of your kitchen. Reach out today to initiate a conversation about your backsplash project – we're here to bring your vision to life!",
      },
      {
        title: "Flooring",
        desc: "Revitalize your space with STL HandyPros' expert flooring services. Our skilled team excels in transforming interiors with flawless flooring installations, whether it's hardwood, laminate or tile. From the initial consultation to meticulous installation, we ensure a seamless process focused on precision and quality. Trust us to not only enhance the aesthetics of your space but also improve its durability and functionality. Whether it's a minor repair or a complete flooring overhaul, STL HandyPros is your trusted partner for top-notch flooring solutions. Elevate your home with our expertise – contact us today to discuss your flooring project.",
      },
      {
        title: "Closets and Pantries",
        desc: "Transform your living spaces with tailored storage solutions from STL HandyPros. Our experts specialize in creating custom pantry and closet designs that not only maximize space but also reflect your unique style. From conceptualization to precise installation, we ensure every detail is considered to enhance functionality and organization. Say goodbye to clutter and hello to personalized, well-organized spaces that elevate the aesthetics of your home. Contact STL HandyPros today to discuss how our custom pantry and closet solutions can bring both efficiency and elegance to your living spaces.",
      },
    ],
  },

  {
    id: "home-repair-and-upkeep",
    thumbnail: homeRepair,
    title: "Home Repair & Upkeep",
    desc: "At STL HandyPros, we understand the importance of timely home repair and consistent upkeep to maintain your property's value and comfort. Our skilled team excels in a wide array of home repair services, addressing everything from minor fixes to major renovations. Whether it's patching up walls, fixing plumbing issues, repairing electrical faults, or conducting routine maintenance checks, we handle each task with expertise and dedication. Trust us to ensure that your home remains a safe, functional, and welcoming space for you and your family.    ",
    accordions: [
      {
        title: "Cabinet Installation & Repair",
        desc: "Transform your space with STL HandyPros' skilled cabinet installation and repair services. Whether you're envisioning the addition of stylish new cabinets or seeking efficient repairs, our expert team is at your service. From precise installations that enhance the aesthetics of your kitchen or bathroom to meticulous repairs that restore functionality, we approach every project with a commitment to quality. Rely on STL HandyPros to bring versatility and craftsmanship to your cabinet needs. Contact us today, and let's explore how our services can tailor to meet your specific requirements. Please also use this on the other pages accordions",
      },
      {
        title: "Kitchen Backsplash Installation",
        desc: "Adding a backsplash in your kitchen is a stylish and modern method to introduce color and dimension to your space. Recognizing the complexity of this project, our skilled experts at STL HandyPros are trained and equipped with the necessary tools to ensure a flawless execution. Let us assist you in discovering the perfect pattern to enhance the natural beauty and personality of your kitchen. Reach out today to initiate a conversation about your backsplash project – we're here to bring your vision to life!",
      },
      {
        title: "Flooring",
        desc: "Revitalize your space with STL HandyPros' expert flooring services. Our skilled team excels in transforming interiors with flawless flooring installations, whether it's hardwood, laminate or tile. From the initial consultation to meticulous installation, we ensure a seamless process focused on precision and quality. Trust us to not only enhance the aesthetics of your space but also improve its durability and functionality. Whether it's a minor repair or a complete flooring overhaul, STL HandyPros is your trusted partner for top-notch flooring solutions. Elevate your home with our expertise – contact us today to discuss your flooring project.",
      },
      {
        title: "Closets and Pantries",
        desc: "Transform your living spaces with tailored storage solutions from STL HandyPros. Our experts specialize in creating custom pantry and closet designs that not only maximize space but also reflect your unique style. From conceptualization to precise installation, we ensure every detail is considered to enhance functionality and organization. Say goodbye to clutter and hello to personalized, well-organized spaces that elevate the aesthetics of your home. Contact STL HandyPros today to discuss how our custom pantry and closet solutions can bring both efficiency and elegance to your living spaces.",
      },
    ],
  },
];
