import React, { useState } from "react";
import "./home.scss";
import CTA from "../../components/cta-section/CTA";
import Testimonials from "../../components/testimonials/Testimonials";
import ServiceBanner from "../../components/service-banner/ServiceBanner";
import homeau from "../../assets/images/homeau.png";
import homemaintain from "../../assets/images/homemaintain.png";
import refrshing from "../../assets/images/refrshing.png";
import homepackages from "../../assets/images/homepackages.png";
import vectorlarge from "../../assets/images/vectorlarge.png";
import vectortwolarge from "../../assets/images/vectortwolarge.png";
import vectorsmall from "../../assets/images/Vectorsmall.png";
import ScheduleAppointment from "../../components/schedule-appointment/ScheduleAppointment";
// import GetAnEstimate from "../../components/get-an-estimate/GetAnEstimate";
import BannerHero from "../../components/hero-section/BannerHero";
import AnimationWrapper from "../../common/animation-wrapper/AnimationWrapper";

const Home = () => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  return (
    <AnimationWrapper>
      <div className="home">
        <BannerHero />

        {/* <div className="section extra-two">
          <div className="container">
            <div className="row">
              <div class="column">
                <img src={homeau} alt="homeau" />
              </div>
              <div class="column">
                <div className="head">
                  <h4>
                    <span class="line"></span>About us
                  </h4>
                  <h3>
                    Guaranteed Professional and Reliable Handyman Services
                  </h3>
                  <p>
                    Our HandyPros undergo a thorough background check, are
                    bonded and insured, and have verified skills. Once your job
                    is complete, you will receive receipts and photos along with
                    your invoice, providing transparent pricing, quality work
                    and peace of mind.
                  </p>
                </div>
                <div className="stripe">
                  <div className="col">
                    <h5>1,162+</h5>
                    <p>Completed Projects</p>
                  </div>
                  <div className="col">
                    <h5>100%</h5>
                    <p>Satisficed Customers</p>
                  </div>
                  <div className="col">
                    <h5>150+</h5>
                    <p>Professional Handyman</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src={vectorlarge} alt="vectorlarge" className="vectorlarge" />
          <img src={vectorsmall} alt="vectorsmall" className="vectorsmall" />
        </div> */}

        <div className="section tabs">
          <div className="container">
            <div className="tabs-container">
              <div className="tab-buttons">
                <button
                  className={activeTab === 1 ? "active-tab" : ""}
                  onClick={() => handleTabClick(1)}
                >
                  Schedule Appointment
                </button>
                {/* <button
                  className={activeTab === 2 ? "active-tab" : ""}
                  onClick={() => handleTabClick(2)}
                >
                  Get An Estimate
                </button> */}
              </div>
              <div className="tab-content">
                {activeTab === 1 && (
                  <AnimationWrapper>
                    <ScheduleAppointment />
                  </AnimationWrapper>
                )}
                {/* {activeTab === 2 && (
                  <AnimationWrapper>
                    <GetAnEstimate />
                  </AnimationWrapper>
                )} */}
              </div>
            </div>
          </div>
        </div>

        {/* <ServiceBanner /> */}

        <div className="section extra">
          <div className="container">
            <div className="row">
              <div class="column">
                <div className="head">
                  <h4>
                    <span class="line"></span>Home maintenance
                  </h4>
                  <h3>Home maintenance is as sure as the changing seasons.</h3>
                  <p>
                    For the seasonal maintenance STL HandyPros understands the
                    significance of seasonal maintenance in preserving the
                    integrity and comfort of your home. As the seasons change,
                    so do the demands on your property, making routine
                    maintenance crucial for optimal performance. That's why we
                    offer specialized preventive maintenance packages tailored
                    for landlords, homeowners, and short-term rental owners. Our
                    skilled team is dedicated to ensuring your property is
                    well-prepared for every season, addressing potential issues
                    before they become major concerns. Trust STL HandyPros to
                    safeguard your investment and provide peace of mind through
                    our comprehensive and customizable seasonal maintenance
                    solutions.
                  </p>
                </div>
              </div>
              <div class="column">
                <img src={homemaintain} alt="homemaintain" />
              </div>
            </div>
          </div>
          <img src={vectortwolarge} alt="vectorlarge" className="vectorlarge" />
          <img src={vectorsmall} alt="vectorsmall" className="vectorsmall" />
        </div>

        {/* <div className="section extra-two">
          <div className="container">
            <div className="row">
              <div class="column">
                <img src={refrshing} alt="homemaintain" />
              </div>
              <div class="column">
                <div className="head">
                  <h4>
                    <span class="line"></span>refreshing
                  </h4>
                  <h3>Don't remodel, refresh!</h3>
                  <p>
                    Why remodel when you can refresh? Skip the expensive
                    renovations and opt for a refresh instead. Think an accent
                    wall in the dining room, stylish built-in bookshelves for
                    the office, updated fixtures in the bathroom, or a fresh
                    backsplash in the kitchen. Small, swift changes can breathe
                    new life into any room. Share your list of ideas with us;
                    we're experts in the art of refreshing spaces.
                    <br /> <br />
                    Give us your list—we specialize in 1,162 different projects.
                    Whether you're prepping your home for sale or settling into
                    a new one, chances are your punch list is within our skill
                    set. Let us tackle those 1,162 home projects—bet your list
                    fits right in.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img src={vectorlarge} alt="vectorlarge" className="vectorlarge" />
          <img src={vectorsmall} alt="vectorsmall" className="vectorsmall" />
        </div> */}

        {/* <div className="section extra">
          <div className="container">
            <div className="row">
              <div class="column">
                <div className="head">
                  <h4>
                    <span class="line"></span>Home packages
                  </h4>
                  <h3>Half-Day & Full-Day Packages</h3>
                  <p>
                    <ul>
                      <li>
                        From quick upgrades to extensive home improvement
                        projects, and those often-forgotten tasks at home,
                        HandyPros has your back. Our team of HandyPros is
                        dedicated to easing the burden of lingering repairs and
                        to-do lists. Curious about our service? Try our 2-hour
                        package and witness firsthand what HandyPros can achieve
                        for you. Share your tasks with us during your call, and
                        we'll provide an estimate for the required time to
                        address your needs.
                      </li>
                      <li>
                        You have the flexibility to book a single Pro for an
                        hour or coordinate multiple Pros for larger projects—all
                        of our Pros are employees, not subcontractors, ensuring
                        quality and reliability throughout."
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
              <div class="column">
                <img src={homepackages} alt="homepackages" />
              </div>
            </div>
            <img
              src={vectortwolarge}
              alt="vectorlarge"
              className="vectorlarge"
            />
            <img src={vectorsmall} alt="vectorsmall" className="vectorsmall" />
          </div>
        </div> */}

        {/* <Testimonials /> */}

        <CTA
          heading={"contact us"}
          subheading={
            "Own a Piece of the Brand America Trusts – Franchises Available"
          }
          desc={
            "Our commitment to delivering a reliable and professional service has earned us a remarkable 4.9-star customer rating. We achieve this by fostering an environment where skilled HandyPros thrive. Looking to join us? Keep an eye out for franchise opportunities available in 2025.            "
          }
          buttonName={"schedule appointment"}
        />
      </div>
    </AnimationWrapper>
  );
};

export default Home;
