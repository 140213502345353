import React from "react";
import "./service-card.scss";
import { Link } from "react-router-dom";
import { GoArrowUpRight } from "react-icons/go";

const ServiceCard = ({ link, image, heading, description }) => {
  const openModal = () => {
    if (window.HCPWidget && typeof window.HCPWidget.openModal === "function") {
      window.HCPWidget.openModal();
    }
  };
  return (
    <div className="service-card">
      <img src={image} alt={heading} />
      <div className="content">
        <h2>
          <Link to={link}>{heading}</Link>
        </h2>
        <p>
          <Link to={link}>{description}</Link>
        </p>
        {/* <hr /> */}
        {/* <ul>
          {points.map((point, index) => (
            <li key={index}>
              <Link to={point.link}>
                {point.text}
              </Link>
            </li>
          ))}
        </ul> */}
        <div className="buttons">
          <button
            class="hcp-button"
            data-token="765abcf35e624c58b5680fa0a1ca9a95"
            data-orgname="STL-HandyPros"
            onClick={openModal}
          >
            <p>Hire a Pro</p>
            <div className="arrow-icon-box">
              <GoArrowUpRight className="upright-arrow-icon" />
            </div>
          </button>
          <Link to={link}>
            <p>View Details</p>
            <div className="arrow-icon-box">
              <GoArrowUpRight className="upright-arrow-icon" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
