import React from "react";
import "./banner-hero.scss";
// import { Link } from "react-router-dom";
import { GoArrowUpRight } from "react-icons/go";
import homebanner from "../../assets/images/homebanner.png";
import homeman from "../../assets/images/homeman.png";
import homevectoranother from "../../assets/images/homevectoranother.svg";

const BannerHero = () => {
  const openModal = () => {
    if (window.HCPWidget && typeof window.HCPWidget.openModal === "function") {
      window.HCPWidget.openModal();
    }
  };
  return (
    <div className="banner-hero">
      <div className="section inner-hero">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="head">
                <h4>
                  <span class="line"></span>Hire a Pro!
                </h4>
                <h3>Why Hire a Handyman When You Can Hire a HandyPro?</h3>
                <p>
                  When it comes to hiring a maintenance person for your home,
                  there is a lot to consider. Confidence that they know how to
                  do the job, feeling safe in having them in your home, peace of
                  mind if something breaks, and trust that you won’t be charged
                  more than you have agreed.
                  <br />
                  <br />
                  Our HandyPros undergo a thorough background check, are bonded
                  and insured, and have verified skills. Once your job is
                  complete, you will receive receipts and photos along with your
                  invoice, providing transparent pricing, quality work and peace
                  of mind.
                </p>
                <button
                  data-token="765abcf35e624c58b5680fa0a1ca9a95"
                  data-orgname="STL-HandyPros"
                  class="hcp-button"
                  onClick={openModal}
                >
                  <p>Schedule Appointment</p>
                  <div className="arrow-icon-box">
                    <GoArrowUpRight className="upright-arrow-icon" />
                  </div>
                </button>
              </div>
            </div>
            <div className="col">
              <img src={homebanner} alt="homebanner" className="" />
              <img src={homeman} alt="homeman" className="homeman" />
            </div>
          </div>
          {/* <img src={homeman} alt="homeman" className="homeman" /> */}
        </div>
        <img
          src={homevectoranother}
          alt="homevectoranother"
          className="homevector"
        />
      </div>
    </div>
  );
};

export default BannerHero;
