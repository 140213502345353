import React, { useEffect } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import { useLocation } from "react-router-dom";
import Routers from "../../routers/Routers";
import AnimationWrapper from "../../common/animation-wrapper/AnimationWrapper";

const Layout = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <AnimationWrapper>
        <Navbar />
        <Routers />
        <Footer />
      </AnimationWrapper>
    </div>
  );
};

export default Layout;
